import React from 'react'
import Image from "next/image";
import afterTeam from "../public/After-Persons-Group.png";


const AfterTeam = () => {



    return (

     <Image alt={'A team that started working together.'} src={afterTeam} className={'w-5/12'}/>




    );
}

export default AfterTeam
