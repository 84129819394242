import React from 'react'


interface IProps {
title: string;
children:any;
className?: string;
}

const Custom = (props: IProps) => {

    return (
        <div className={'w-full bg-white flex flex-col  md:items-center justify-center   p-4 md:p-8 ' + props.className}>
            <h1 className={"pt-6  font-mono text-2xl  font-extrabold  sm:text-3xl"}>
                {props.title}
            </h1>

            <div
                className={
                    "  w-fit flex flex-col md:items-center  pb-8"
                }
            >
                {props.children}

            </div>


        </div>


    );
}

export default Custom
