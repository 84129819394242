import React from 'react'
import ContentContainer from "./ContentContainer";
import EmailSub from "./EmailSub";


interface IProps {
 text: string;
 tag: string;
 className?: string;
}

const SubsribeSection = (props: IProps) => {

    return (
        <div className={"flex h-full w-full flex-col items-center bg-white"}>
            <ContentContainer
                className={"mt-24 mb-24 flex flex-col items-center w-full"}
            >
                <div className={"flex w-full flex-col items-center sm:w-2/3"}>
                    <div className={"text-xl text-center font-extrabold  md:text-2xl"}>
                        {props.text}
                    </div>
                    <div className={"w-full self-center pt-8  md:self-start flex flex-col items-center justify-center"}>

                        <EmailSub tag={props.tag}/>
                    </div>
                </div>
            </ContentContainer>
        </div>


    );
}

export default SubsribeSection
