import React from "react";

interface IProps {
  animate: boolean;
  word: string;
  gradient: string;
}

const AnimatedWord = (props: IProps) => {
  return (
    <div className={"relative flex flex-col items-center"}>
      <div className={"relative"}>
        <div
          className={
            "absolute z-10 bg-clip-text text-transparent  transition-opacity duration-1000   " +
            props.gradient +
            (!props.animate ? " opacity-0" : " opacity-100")
          }
        >
          {props.word}
        </div>
        <div
          className={
            "absolute -z-10 translate-x-0.5  translate-y-0.5 text-brand-black  opacity-50  transition-opacity duration-75   "
          }
        >
          {props.word}
        </div>

        <div
          className={
            " text-white transition-opacity duration-1000 " +
            (props.animate ? "opacity-0" : "opacity-100")
          }
        >
          {props.word}
        </div>
      </div>
    </div>
  );
};

export default AnimatedWord;
