import React from 'react'
import {getOpacityFromBottomTransition} from "../lib/Transitions";
import {useInView} from "react-intersection-observer";


interface IProps {
    title: any;
    subtitle?: any;

    subtitleClassName?: any;
}

const SectionHeader = (props: IProps) => {

    const trainingsHeaderInView = useInView({
        triggerOnce: true,
        rootMargin: "-50px 0px",
    });
    return (
        <>
            <h1
                ref={trainingsHeaderInView.ref}
                className={
                    "shadowedText  font-mono uppercase text-4xl md:text-4xl  font-extrabold  tracking-wider  text-white lg:text-6xl " +
                    getOpacityFromBottomTransition(trainingsHeaderInView.inView)
                }
            >
                {props.title}
            </h1>
            {props.subtitle && (
            <h2
                className={
                props.subtitleClassName +    " shadowedText  text-base font-mono md:text-xl  text-white  " +
                    getOpacityFromBottomTransition(trainingsHeaderInView.inView)}>

                {props.subtitle}
            </h2>
            )}
        </>

    );
}

export default SectionHeader
