import React from "react";

interface IProps {
    className?: string;
}

const Logo = (props: IProps) => {
    return (
        <div
            className={
                " flex w-full flex-row font-sans  text-xl font-normal text-white  " +
                props.className
            }
        >
            <div className={"mr-1.5 w-[35px]"}>



                <svg width="100%" height="100%" viewBox="0 0 496 496" fill="none" xmlns="http://www.w3.org/2000/svg">

                    <path
                        d="M155.343 368.745C53.6624 387.266 7.49245 314.807 0 282.576V33.0777C-0.000121948 6.02483 16.9829 -0.29052 37.4622 0.0102145L238 0.0101861C207.2 0.0101861 174.65 23.5 155.343 42.0936C85.1572 109.687 149.849 178.589 184.814 221.452C209.733 251.999 261 349.5 155.343 368.745Z"
                        fill="#FF00B5"/>
                    <path
                        d="M386.61 91.192C441.055 43.5971 411.8 18.9996 408.5 16C403 11 391 5 376 0.0101861H466.53C485.526 0.0106692 496 15.0421 496 28.0669L496 457.923C496 487.482 481.515 496.116 456.54 495.999C349.481 495.498 128.97 494.796 69.4301 495.999H30.4692C8.49144 495.999 0.998871 478.464 0.998749 463.434V410.328C49.4502 503.514 194.804 502.011 276.721 437.382C358.638 372.753 329.168 273.555 313.184 247.002C288.803 206.499 250.747 175.861 218.779 142.795C178.348 100.975 203.295 48.1061 276.721 42.0936C343.232 36.6474 378.785 74.659 386.61 91.192Z"
                        fill="#FF00B5"/>
                </svg>


            </div>
            <div className={"font-molle text-[2.5rem] md:text-[1.2rem] lg:text-[2.5rem] lg:translate-y-[-2px] md:translate-y-[-1px] translate-y-[2px] flex flex-col justify-center "}>

                <p>
                    Scrumify
                </p>

            </div>
        </div>
    );
};

export default Logo;
