import React from 'react'
import Image from "next/image";
import beforeTeam from "../public/Before-Persons-Group.png";



const BeforeTeam = () => {

    return (


      <Image alt={'A Team that is not working together.'} src={beforeTeam} className={'w-5/12'}/>

    );
}

export default BeforeTeam
