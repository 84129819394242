import React, { useRef, useState } from "react";
import PopInButton from "./PopInButton";
import { Form, FormState } from "../lib/Types";
import { Loader } from "react-feather";

interface IProps {
  onClick?: () => void;
  tag: string;
}

const EmailSub = (props: IProps) => {
  const [form, setForm] = useState<FormState>({ state: Form.Initial });
  const inputEl = useRef(null);
  const inputName = useRef(null);
  const subscribe = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setForm({ state: Form.Loading });
    // @ts-ignore
    const email = inputEl.current.value;
      // @ts-ignore
    const name = inputName.current.value;
    const res = await fetch(`/api/sub?email=${email}&name=${name}&tag=${props.tag}`, {
      method: "POST",
    });

    const { error } = await res.json();
    if (error) {
      setForm({
        state: Form.Error,
        message: error,
      });
      return;
    }
    // @ts-ignore
    inputEl.current.value = "";
    setForm({
      state: Form.Success,
      message: `Awesome! Thank you for subscribing!`,
    });
  };

  return (
    <>
      <form className={"flex h-full w-full flex-col space-y-2"} onSubmit={subscribe}>

        <input
            onClick={props.onClick}
            type="given-name"
            ref={inputName}
            aria-label="Name for newsletter"
            className="w-full  border-2  border-brand-pink rounded-none p-4 outline-none"
            placeholder="Steve"
            required
            autoComplete="given-name"
        />
        <input
          onClick={props.onClick}
          type="email"
          ref={inputEl}
          aria-label="E-mail for newsletter"
          className="w-full  border-2  border-brand-pink rounded-none p-4 outline-none"
          placeholder="steve@apple.com"
          required
          autoComplete="email"
        />



          <PopInButton
            type={"submit"}
            className={"flex flex-col items-center h-11"}
            ariaLabel={"Submit signup for newsletter"}
            backgroundColor={'bg-brand-turquoise'}
          >
            {form.state === Form.Loading ? (
              <Loader className={"animate-spin"} />
            ) : (
                <p className={'text-lg '}>
                  Sign me up!
                </p>
            )}
          </PopInButton>
      </form>

      <p
        className={
          "text-sm " +
          (form.state === Form.Error && " text-yellow-500 ") +
          (form.state === Form.Success && "text-green-400 ")
        }
      >
        {form.message}
      </p>
    </>
  );
};

export default EmailSub;
