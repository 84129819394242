import React, {useEffect, useState} from "react";
import NavButton from "./NavButton";
import { Menu, X} from "react-feather";
import {getOpacityFromBottomTransition} from "../lib/Transitions";
import {useRouter} from "next/router";

interface IProps {
    floatingNavStatus: FloatingNavStatus;
    scroller: any
    initialShowStatus: boolean;
}

export enum FloatingNavStatus {
    TOP = "start",
    PACKAGES = "packages",
    WORKSHOPS = "workshops",
    TRAININGS = "trainings",
    ABOUT = "about",
    CONTACT = "contact",
    RETRO= "ai-retro",
    NONE = "none",
}



const FloatingNav = (props: IProps) => {
    // Somewhere else, even another file

    const router = useRouter();
    function checkIfOnRootPage(hashtag?:string) {
        if(router.pathname !== '/'){
            router.push('/' + (hashtag ? hashtag : ''))
        }
    }
    const [didScroll, setDidScroll] = useState(props.initialShowStatus);
    useEffect(() => {
        let previousScrollYPosition = window.scrollY;

        const scrolledMoreThanThreshold = (currentScrollYPosition: number) =>
            Math.abs(currentScrollYPosition - previousScrollYPosition) > 0;


        const updateScrollDirection = () => {
            const currentScrollYPosition = window.scrollY;

            if (scrolledMoreThanThreshold(currentScrollYPosition)) {

                setDidScroll(true)
            }
        };

        const onScroll = () => window.requestAnimationFrame(updateScrollDirection);

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const [isMenuOpen, setIsMenuOpen] = useState(false);


    return (
        <div
            className={
                "pointer-events-none fixed bottom-0  z-50 mb-4 block w-full md:hidden "
            }
        >

            <div className={"flex w-full flex-col items-center "}>
                <div className={
                    "pointer-events-auto flex h-14  flex-row justify-around     bg-brand-black font-mono text-xl font-extrabold text-white w-3/4 sm:text-md  md:text-xl  delay-1000 " +
                    getOpacityFromBottomTransition(didScroll)
                }>
                    <button className={'flex w-full  flex-row justify-center items-center'} onClick={() => setIsMenuOpen(prevState => !prevState)}>

                        { !isMenuOpen ?   <><Menu size={25} className={'mr-2'}/> MENU</> : <X/>}

                    </button>
                    <div className={'absolute w-full  bottom-0  transition-all duration-500 overflow-hidden bg-red-100 -translate-y-[55px] ' + (isMenuOpen ? 'h-[240px] ' : ' h-0 ')}>
                        <div
                            className={
                                "pointer-events-auto flex  flex-col justify-around border-[1px] border-gray-700  bg-brand-black font-mono  font-extrabold text-white " +
                                " delay-1000 " +
                                getOpacityFromBottomTransition(didScroll)
                            }
                        >
                            <NavButton
                                floatingNavStatus={FloatingNavStatus.NONE}
                                borderColor={"border-brand-black"}
                                onClick={() => {
                                    checkIfOnRootPage();
                                    props.scroller.scrollTo("topScrollToElement", {
                                        duration: 1500,
                                        delay: 100,
                                        smooth: true,
                                    });
                                    setIsMenuOpen(false);
                                }}
                                buttonType={FloatingNavStatus.TOP}
                            >
                                HOME
                            </NavButton>

                            <NavButton
                                borderColor={"border-brand-black"}
                                floatingNavStatus={props.floatingNavStatus}
                                buttonType={FloatingNavStatus.PACKAGES}
                                onClick={() => {
                                    checkIfOnRootPage("#packages");
                                    props.scroller.scrollTo("packages", {
                                        duration: 1500,
                                        delay: 100,
                                        smooth: true,
                                    });
                                    setIsMenuOpen(false);
                                }}
                            >
                                PACKAGES
                            </NavButton>


                            <NavButton
                                borderColor={"border-brand-black"}
                                floatingNavStatus={props.floatingNavStatus}
                                buttonType={FloatingNavStatus.ABOUT}
                                onClick={() => {
                                    checkIfOnRootPage("#about");
                                    props.scroller.scrollTo("about", {
                                        duration: 1500,
                                        delay: 100,
                                        smooth: true,
                                    });
                                    setIsMenuOpen(false);
                                }}
                            >
                                ABOUT
                            </NavButton>
                            <NavButton
                                borderColor={"border-brand-black"}
                                floatingNavStatus={props.floatingNavStatus}
                                buttonType={FloatingNavStatus.TRAININGS}
                                onClick={() => {
                                    checkIfOnRootPage("#trainings");
                                    props.scroller.scrollTo("trainings", {
                                        duration: 1500,
                                        delay: 100,
                                        smooth: true,
                                    });

                                    setIsMenuOpen(false);

                                }}

                            >
                                TRAININGS
                            </NavButton>




                            <NavButton
                                borderColor={"border-brand-black"}
                                floatingNavStatus={props.floatingNavStatus}
                                buttonType={FloatingNavStatus.CONTACT}
                                onClick={() => {
                                    checkIfOnRootPage("#contact");
                                    props.scroller.scrollTo("contact", {
                                        duration: 1500,
                                        delay: 100,
                                        smooth: true,
                                    });

                                    setIsMenuOpen(false);
                                }}
                            >
                                CONTACT
                            </NavButton>
                            <NavButton
                                borderColor={"border-brand-black"}
                                floatingNavStatus={props.floatingNavStatus}
                                buttonType={FloatingNavStatus.RETRO}
                                onClick={() => {
                                    router.push('/airetro')
                                    setIsMenuOpen(false);
                                }}
                            >
                                AI RETRO 💡
                            </NavButton>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
};

export default FloatingNav;
